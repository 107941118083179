<template>
  <div class="containe">
    <div class="row">
      <div class="col">
        <div class="card">
          <PrescriptionEditor
            :title="
              activeContenu && activeContenu.id
                ? 'Ajouter prescription'
                : 'Modifier prescription'
            "
            :item="activePrescription"
            ref="prescriptionEditor"
            @save="savePrescritionItem"
          />
          <ContenuPrescriptionEditor
            :title="
              activeContenu && activeContenu.id
                ? 'Ajouter contenu prescription'
                : 'Modifier contenu prescription'
            "
            :item="activeContenu"
            ref="contenuPrescriptionEditor"
            @save="saveContenuPrescritionItem"
          />
          <DossierValidationDialog
            :ref="'dossierValidator'"
            v-show="activeDemandeur"
            :demandeur="activeDemandeur"
            @save="validerFiche"
          ></DossierValidationDialog>
          <PDataTable
            class="shadow-none"
            :value="prescriptions"
            :expandedRows.sync="expandedRows"
            stripedRows
            editMode="row"
            dataKey="id"
            :editingRows.sync="editingRows"
          >
            <PColumn :expander="true" />
            <PColumn
              field="typePrescription"
              :styles="{ width: colWidth }"
              header="Prescription"
            >
              <template #body="slotProps">
                <a
                  :class="{
                    'fw-bold': isSpecialPrescriptionType(slotProps.data.typePrescription),
                  }"
                  href=""
                  @click.prevent="toggleExpandRow(slotProps.data)"
                >
                  {{ slotProps.data.typePrescription.libelle }}
                  <!-- <PBadge v-if="slotProps.data.contenus.length>0" :value="slotProps.data.contenus.length" severity="primary"></PBadge> -->
                </a>
              </template>
            </PColumn>
            <PColumn field="contenu" :styles="{ width: colWidth }" header="Contenu">
              <template #body="slotProps">
                <PBadge
                  v-if="slotProps.data.contenus.length > 0"
                  :value="slotProps.data.contenus.length"
                  severity="primary"
                  @click.prevent="toggleExpandRow(slotProps.data)"
                ></PBadge>
              </template>
            </PColumn>
            <PColumn field="dateDebut" :styles="{ width: colWidth }" header="Date Début">
              <template #editor="slotProps">
                <PInputText v-model="slotProps.data[slotProps.column.field]" />
              </template>
              <template #body="slotProps">
                <span
                  v-tooltip.top="
                    $dayjs(slotProps.data[slotProps.column.field]).format(
                      'DD-MM-YYYY HH:mm'
                    )
                  "
                  v-if="slotProps.data[slotProps.column.field]"
                  >{{
                    slotProps.data[slotProps.column.field] | dayjs("DD-MM-YYYY")
                  }}</span
                >
                <span v-else>NA</span>
              </template>
            </PColumn>
            <PColumn field="dateFin" :styles="{ width: colWidth }" header="Date Fin">
              <template #editor="slotProps">
                <PInputText v-model="slotProps.data[slotProps.column.field]" />
              </template>
              <template #body="slotProps">
                <span
                  v-tooltip.top="
                    $dayjs(slotProps.data[slotProps.column.field]).format(
                      'DD-MM-YYYY HH:mm'
                    )
                  "
                  v-if="slotProps.data[slotProps.column.field]"
                  >{{
                    slotProps.data[slotProps.column.field] | dayjs("DD-MM-YYYY")
                  }}</span
                >
                <span v-else>NA</span>
              </template>
            </PColumn>
            <PColumn field="etat" header="Etat">
              <template #body="slotProps">
                <!-- {{ slotProps.data.etat || 'N/A' }} -->
                <PBadge
                  :value="slotProps.data.etat"
                  :severity="getEtatColor(slotProps.data.etat)"
                />
              </template>
            </PColumn>

            <PColumn
              field="observation"
              :styles="{ width: colWidth }"
              header="Observation"
            >
              <template #editor="slotProps">
                <PInputText v-model="slotProps.data[slotProps.column.field]" />
              </template>
            </PColumn>
            <PColumn field="activites" header="Activites">
              <template #body="slotProps">
                <PBadge
                  v-if="slotProps.data.activites"
                  :value="slotProps.data.activites"
                />
                <span v-else>-</span>
              </template>
            </PColumn>

            <PColumn field="fullFileUrl" header="Pièce jointe">
              <template #body="slotProps">
                <a
                  v-if="slotProps.data.fullFileUrl"
                  href="#"
                  @click.prevent="
                    downloadFile({
                      url: slotProps.data.fullFileUrl,
                    })
                  "
                  ><i
                    class="text-primary bi bi-file-earmark-arrow-down"
                    style="font-size: 1.6rem"
                  ></i
                ></a>
              </template>
            </PColumn>
            <PColumn
              field="typePrescription"
              :styles="{ width: colWidth }"
              header="Action"
            >
              <template #body="slotProps">
                <div v-if="!isSpecialPrescriptionType(slotProps.data.typePrescription)">
                  <div class="d-flex justify-space-between">
                    <PButton
                      :disabled="readOnly"
                      @click.prevent="$refs.prescriptionEditor.show()"
                      icon="pi pi-pencil"
                      class="p-button-rounded p-button-primary"
                      @click="editPrescriptionItem(slotProps.data)"
                    />
                    <PButton
                      :disabled="readOnly"
                      icon="pi pi-plus"
                      class="p-button-rounded p-button-outlined p-button-primary"
                      @click="addContenuPrescritionItem(slotProps.data)"
                    />
                    <PButton
                      :disabled="readOnly"
                      icon="pi pi-trash"
                      class="p-button-rounded p-button-warning"
                      @click="confirmDeleteItem(slotProps.data)"
                    />
                  </div>
                </div>
              </template>
            </PColumn>

            <template #expansion="slotProps">
              <div v-if="slotProps.data.typePrescription.code == 'PROGRAMME'">
                <!-- v-if="slotProps.data.participationProgrammes" -->
                <ProgrammesBeneficies
                  :extraQueryArg="`prescriptionId=${slotProps.data.id}`"
                  :demandeur="demandeur"
                  :prescription="slotProps.data"
                >
                  <template #header="{ addProgramme }">
                    <div class="d-flex justify-content-between">
                      <h5 class="mb-0 bg-warning text-uppercase p-1 w-100">
                        Programmes bénéficiés
                      </h5>
                      <a
                        @click.prevent="addProgramme"
                        class="btn btn-primary rounded-0 btn-sm"
                        ><i class="bi bi-clipboard-plus"></i
                      ></a>
                    </div>
                  </template>
                </ProgrammesBeneficies>
              </div>
              <div v-else-if="slotProps.data.typePrescription.code == 'MICROPROJET'">
                <template
                  v-if="
                    priseEnCharge != null && priseEnCharge.cheminEmploi == 'INDEPENDANT'
                  "
                >
                  <div v-if="demandeur.npiValide == null">
                    <PMessage
                      v-if="demandeur.ficheValide"
                      class="mb-0 p-0 msg-cloture"
                      severity="success"
                    >
                      Le dossié de {{ demandeur.nom }} a été validé avec succès.
                    </PMessage>
                    <div class="card" v-if="demandeur.npiValide == null">
                      <div class="card-body text-center">
                        <div class="mb-2">
                          Le
                          <span class="fw-bold">NPI</span>
                          de
                          <span class="fw-bold"
                            >{{ demandeur.nom }} {{ demandeur.prenom }}</span
                          >
                          doit d'abord être validé avant de procéder à la création du
                          microprojet.
                        </div>
                        <PButton
                          :disabled="readOnly"
                          class="p-button-danger"
                          @click="showDossierValidationDialog(demandeur)"
                          >Valider le NPI du DE</PButton
                        >
                      </div>
                    </div>
                  </div>
                  <Microprojets
                    v-else
                    :extraQueryArg="`prescriptionId=${slotProps.data.id}`"
                    :demandeur="demandeur"
                    :prescription="slotProps.data"
                  >
                    <template #header="{ addMicroprojet }">
                      <div class="d-flex justify-content-between">
                        <h5 class="mb-0 bg-warning text-uppercase p-1 w-100">
                          Liste des microprojets du bénéficiaire
                        </h5>
                        <a
                          @click.prevent="addMicroprojet"
                          class="btn btn-primary rounded-0 btn-sm"
                          ><i class="bi bi-clipboard-plus"></i
                        ></a>
                      </div>
                    </template>
                  </Microprojets>
                </template>
                <div v-else>
                  <div class="card">
                    <div class="card-body text-center">
                      <div class="mb-2">
                        Le DE
                        <span class="fw-bold"
                          >{{ demandeur.nom }} {{ demandeur.prenom }}</span
                        >
                        a opté pour l'emploi
                        <PTag :severity="'danger'">SALARIE</PTag>
                        . En conséquence, il n'est pas éligible au microprojet.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="slotProps.data.typePrescription.code == 'FI-APE'">
                <template
                  v-if="
                    slotProps.data.microprojets &&
                    priseEnCharge != null &&
                    priseEnCharge.cheminEmploi == 'INDEPENDANT'
                  "
                >
                  <EvaluationEntrepreneuriale :prescription="slotProps.data" />
                </template>
                <div v-else>
                  <div class="card">
                    <div class="card-body text-center">
                      <div class="mb-2">
                        Le DE
                        <span class="fw-bold"
                          >{{ demandeur.nom }} {{ demandeur.prenom }}</span
                        >
                        a opté pour l'emploi
                        <PTag :severity="'danger'">SALARIE</PTag>
                        . En conséquence, il n'est pas éligible au microprojet.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="card">
                <PDataTable :value="slotProps.data.contenus">
                  <PColumn field="libelle" header="Libelle" :styles="{ width: colWidth }">
                    <template #body="slotProps">
                      <span v-if="slotProps.data[slotProps.column.field]">{{
                        slotProps.data[slotProps.column.field]
                          | dayjs("DD-MM-YYYY HH:mm")
                      }}</span>
                      <span v-else>NA</span>
                    </template>
                  </PColumn>
                  <PColumn field="dateDebut" header="Date de début">
                    <template #body="slotProps">
                      <span v-if="slotProps.data[slotProps.column.field]">{{
                        slotProps.data[slotProps.column.field]
                          | dayjs("DD-MM-YYYY HH:mm")
                      }}</span>
                      <span v-else>NA</span>
                    </template>
                  </PColumn>
                  <PColumn
                    field="dateFin"
                    header="Date de fin"
                    :styles="{ width: colWidth }"
                  >
                    <template #body="slotProps">
                      <span>{{ slotProps.data[slotProps.column.field] || "N/A" }}</span>
                    </template>
                  </PColumn>
                  <PColumn field="etat" header="Etat" :styles="{ width: colWidth }">
                    <template #body="slotProps">
                      <PBadge
                        :value="slotProps.data.etat"
                        :severity="getEtatColor(slotProps.data.etat)"
                      />
                    </template>
                  </PColumn>
                  <PColumn field="fullFileUrl" header="Pièce jointe">
                    <template #body="slotProps">
                      <a
                        v-if="slotProps.data.fullFileUrl"
                        href="#"
                        @click.prevent="
                          downloadFile({
                            url: slotProps.data.fullFileUrl,
                          })
                        "
                        ><i
                          class="text-primary bi bi-file-earmark-arrow-down"
                          style="font-size: 1.6rem"
                        ></i
                      ></a>
                      <!-- <PBadge :value="slotProps.data.etat" :severity="slotProps.data.etat=='TERMINE'? '':'info'" /> -->
                    </template>
                  </PColumn>
                  <PColumn
                    :styles="{ width: colWidth }"
                    field="observation"
                    header="Observation"
                  ></PColumn>
                  <PColumn :styles="{ width: colWidth }" header="Action">
                    <template #body="innerProps">
                      <div class="d-flex justify-space-between">
                        <PButton
                          icon="pi pi-pencil"
                          class="p-button p-button-primary me-2"
                          :disabled="readOnly"
                          @click="
                            editContenuPrescritionItem(innerProps.data, slotProps.data)
                          "
                        />
                        <PButton
                          :disabled="readOnly"
                          icon="pi pi-trash"
                          class="p-button p-button-warning"
                          @click="
                            removeContenuPrescritionItem(innerProps.data, slotProps.data)
                          "
                        />
                      </div>
                    </template>
                  </PColumn>
                </PDataTable>
              </div>
            </template>
          </PDataTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrescriptionEditor from "./editor/PrescriptionEditor.vue";
import ContenuPrescriptionEditor from "./editor/ContenuPrescriptionEditor.vue";
import { mapActions } from "vuex";
import ProgrammesBeneficies from "../../../../components/espace/common/prise-en-charge/ProgrammesBeneficies.vue";
import Microprojets from "../../../../components/espace/common/prise-en-charge/Microprojets.vue";
import DossierValidationDialog from "../../../../components/espace/demandeur/DossierValidationDialog.vue";
import EvaluationEntrepreneuriale from "./profil-entrepreneurial/EvaluationEntrepreneuriale.vue";

export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
    demandeur: {
      type: Object,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    priseEnCharge: {
      type: Object,
    },
  },
  components: {
    PrescriptionEditor,
    ContenuPrescriptionEditor,
    ProgrammesBeneficies,
    Microprojets,
    DossierValidationDialog,
    EvaluationEntrepreneuriale,
  },
  data() {
    return {
      display: false,
      prescriptions: [
        ...this.value.sort((a, b) =>
          a.typePrescription.ordreAffichage < b.typePrescription.ordreAffichage
            ? -1
            : a.typePrescription.ordreAffichage > b.typePrescription.ordreAffichage
            ? 1
            : 0
        ),
      ],
      expandedRows: [],
      editingRows: [],
      activePrescription: null,
      activeContenu: null,
      activeDemandeur: null,
      activeIndex: null,
    };
  },
  computed: {
    colWidth() {
      return "100%";
    },
    canModify() {
      return !this.readOnly;
    },
    etats() {
      return [
        {
          value: "NON_REALISE",
          label: "Non Réalisé",
        },
        {
          value: "EN_COURS",
          label: "En cours",
        },
        {
          value: "TERMINE",
          label: "Terminé",
        },
      ];
    },
    emptyContenuPrescription() {
      return {
        demandeur: null,
        observation: null,
        prescription: null,
        dateDebut: null,
        dateFin: null,
        fileUrl: null,
        etat: null,
      };
    },
  },
  methods: {
    ...mapActions({
      createContenuPrescription: "prescription/createContenuPrescription",
      createMicroprojet: "microprojet/createMicroprojet",
      updateMicroprojet: "microprojet/updateMicroprojet",
      createParticipationProgramme: "participationProgramme/createParticipationProgramme",
      updateParticipationProgramme: "participationProgramme/updateParticipationProgramme",
      updateMicroprojet: "microprojet/updateMicroprojet",
      updateContenuPrescription: "prescription/updateContenuPrescription",
      deleteContenuPrescription: "prescription/deleteContenuPrescription",
      updateOrCreateContenuPrescription: "prescription/updateOrCreateContenuPrescription",
      updatePrescription: "prescription/updatePrescription",
      downloadFile: "media/downloadFile",
    }),
    showDossierValidationDialog(demandeur) {
      this.activeDemandeur = demandeur;
      this.$refs.dossierValidator.show();
    },
    getEtatColor(etat) {
      let color = "primary";
      if (etat == "NON_REALISE") {
        color = "info";
      } else if (etat == "INVALIDE") {
        color = "danger";
      } else if (etat == "EN_COURS") {
        color = "warning";
      }
      return color;
    },
    async validerFiche(demandeur) {
      console.log(demandeur);
      // this.demandeur.
      // if(demandeur.status == 'VALIDE'){
      //       this.$toast.info(demandeur.estPPI? "Fiche déjà validée!" : 'CV déjà validé', {
      //           position: 'top-right',
      //           duration: 8000
      //         })
      //   return
      // }

      // if(demandeur.status != 'TERMINE'){
      //       this.$toast.error(demandeur.estPPI? "Fiche non terminée" : 'CV non terminé', {
      //           position: 'top-right',
      //           duration: 8000
      //         })
      //   return
      // // }

      // await this.$confirm.require({
      //     group: 'confirmDialog',
      //     message: `Voulez-vous vraiment valider  le NPI de ${demandeur.nom} ${demandeur.prenom}?` ,
      //     header: 'Confirmation',
      //     icon: 'pi pi-exclamation-triangle',
      //     accept: () => {
      //       this.validerDossier({
      //           ...demandeur,
      //           status: 'VALIDE'
      //         }).then(data => {
      //           this.activeDemandeur.ficheValide = true
      //           this.activeDemandeur.status = 'VALIDE'
      //           this.$toast.success("Fiche validée avec succès!", {
      //               position: 'top-right',
      //               duration: 10000
      //             })
      //         })
      //     },
      //   });
    },
    onRowExpand() {},
    onRowCollapse() {},
    toggleExpandRow(item) {
      const index = this.expandedRows.indexOf((el) => el.id == item.id);
      console.log(this.expandedRows, item, index);
      if (index == -1) {
        this.expandedRows.push(item);
      } else {
        this.expandedRows = [];
      }
    },
    isSpecialPrescriptionType(typePrescription) {
      return ["PROGRAMME", "MICROPROJET"].includes(typePrescription.code);
    },
    editPrescriptionItem(prescription) {
      console.log(prescription);
      this.activePrescription = { ...prescription };
      this.$refs.prescriptionEditor.show();
    },
    removeContenuPrescritionItem(contenu, prescription) {
      this.deleteContenuPrescription(contenu).then(() => {
        this.prescriptions = this.prescriptions.map((presc) => {
          if (prescription.id == presc.id) {
            presc.contenus = presc.contenus.filter((el) => el.id != contenu.id);
          }
          return presc;
        });
        this.$toast.success({
          message: "Contenu supprimé avec succès!",
        });
      });
    },
    editContenuPrescritionItem(contenu, prescription) {
      this.activeContenu = {
        ...contenu,
        prescription,
      };
      this.$refs.contenuPrescriptionEditor.show();
    },
    addContenuPrescritionItem(prescription) {
      this.activeContenu = {
        ...this.emptyContenuPrescription,
        prescription,
      };

      this.$refs.contenuPrescriptionEditor.show();
    },
    async saveContenuPrescritionItem(item) {
      let func = this.createContenuPrescription;

      if (item.id != null) {
        func = this.updateContenuPrescription;
      }

      func(item)
        .then((contenu) => {
          let contenus = item.prescription.contenus;
          this.prescriptions = this.prescriptions.map((presc) => {
            if (presc.id == item.prescription.id) {
              let replaced = false;
              presc.contenus = presc.contenus.map((el) => {
                if (el.id == contenu.id) replaced = true;
                return el.id == contenu.id ? contenu : el;
              });

              if (!replaced) {
                contenus.push(contenu);
                presc.contenus = contenus;
              }
            }
            this.activeContenu = {};
            return presc;
          });
          this.$toast.success({
            message: "Opération réussie",
          });
        })
        .catch((err) => {
          this.$toast.error({
            message: "Une erreur s'est produite." + err,
          });
        });
    },
    async savePrescritionItem(item) {
      this.updatePrescription(item)
        .then((prescription) => {
          this.prescriptions = this.prescriptions.map((presc) => {
            return presc.id != prescription.id ? presc : prescription;
          });
          this.activePrescription = null;
        })
        .catch((err) => {
          this.$toast.error({
            message: "Une erreur s'est produite." + err,
          });
          this.activePrescription = null;
        });
    },
  },
};
</script>
