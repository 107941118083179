import { render, staticRenderFns } from "./EchelonArtisanat.vue?vue&type=template&id=f4fe1662&scoped=true"
import script from "./EchelonArtisanat.vue?vue&type=script&lang=js"
export * from "./EchelonArtisanat.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4fe1662",
  null
  
)

export default component.exports