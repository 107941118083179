<template>
  <div class="container">
    <div class="pt-2 pb-5">
      <div class="bg">
        <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
          <div class="my-1 flex-fill">
            <slot name="listTitle" :listTitle="listTitle">
              <h5>{{ listTitle }}</h5>
            </slot>
          </div>
          <crud-table-editor
            ref="crudTableEditor"
            @saved="updateOrCreateReferentiel"
            btnText="Créer un referentiel"
            :item="activeEditableItem"
            :title="formTitle"
          >
            <template #formTitle>
              <slot name="formTitle">
                <h5>{{ formTitle }}</h5>
              </slot>
            </template>
            <template #formContent="{ updateValue, handleInput, editableItem }">
              <slot
                name="formContent"
                :handleInput="handleInput"
                :editableItem="editableItem"
                :updateValue="updateValue"
              >
                <div>
                  Veuillez définir le contenu du formulaire dans le slot 'formContent' de
                  RenferentielList
                </div>
              </slot>
            </template>
          </crud-table-editor>
        </div>
        <div class="bg-grey py-3 border px-3">
          <div class="table-responsive">
            <b-table
              show-empty
              empty-text="Aucune donnée à afficher"
              :fields="tableFields"
              class="align-middle"
              striped
              select-mode="multi"
              ref="table"
              responsive="sm"
              selectable
              sort-icon-left
              @row-selected="onRowSelected"
              :items="computedItemsList"
            >
              <template #head()="data">
                <span>{{ data.label }} </span>
              </template>

              <template #cell(#)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell()="data">
                {{ data.index + 1 }}
              </template>

              <!-- A virtual column -->
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(actions)="data">
                <div class="d-flex justify-content-end">
                  <a
                    @click.prevent="editReferentiel(data.item)"
                    class="btn btn-success rounded-0 btn-sm me-1"
                    v-tooltip.top="'Modifier'"
                    ><i class="bi bi-pencil-square"></i
                  ></a>
                  <!-- <a class="btn btn-info rounded-0 btn-sm me-1"
                    ><i class="bi bi-info-square"></i
                  ></a> -->
                  <a
                    @click.prevent="destroyReferentiel(data.item)"
                    class="btn btn-danger rounded-0 btn-sm me-1"
                    v-tooltip.top="'Supprimer'"
                    ><i class="bi bi-trash"></i
                  ></a>
                </div>
              </template>
              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                <slot name="tableCellsSlot" :data="data">
                  <CYesOrNoBadge v-if="data.field.isBoolean" :value="data.value" />

                  <span v-else-if="data.field.isDate">{{
                    data.value | dayjs("DD-MM-YYYY")
                  }}</span>
                  <PTag
                    v-else-if="data.field.isBadge"
                    :severity="
                      data.field.colorMapping ? data.field.colorMapping[data.value] : ''
                    "
                    >{{ data.value }}
                  </PTag>
                  <span v-else-if="data.item.isSummaryRow" class="fw-bold">
                    {{ data.value }}
                  </span>
                  <span v-else>{{ data.value }}</span>
                </slot>
              </template>

              <slot></slot>
            </b-table>
          </div>

          <!-- <div class="d-flex flex-wrap justify-content-between align-items-center">
            <p>
              Affichage de l’élément
              {{ mxPagination.page * mxPagination.rowsPerPage }} à
              {{ mxPagination.page * mxPagination.rowsPerPage }} de
              {{ mxTotalCount }} éléments
            </p>
            <b-pagination
              v-model="mxPagination.page"
              :total-rows="mxTotalCount"
              :per-page="mxPagination.rowsPerPage"
              aria-controls="my-table"
            ></b-pagination>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CrudTableEditor from "./CrudTableEditor.vue";
import { get, put, remove, post } from "../../../api/api";
import CYesOrNoBadge from "@/components/common/YesOrNoBadge.vue";

export default {
  props: {
    listTitle: {
      type: String,
      default: null,
    },
    crudUrl: {
      type: String,
      required: true,
    },
    extraQueryArg: {
      type: String,
      default: "",
    },
    formTitle: {
      type: String,
      required: true,
    },
    tableFields: {
      type: Array,
      required: true,
    },
    emptyReferentielItem: {
      type: Object,
      required: true,
    },
    dataFormatter: {
      type: Function,
    },
    totalComputingFields: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    CrudTableEditor,
    CYesOrNoBadge,
  },
  data() {
    return {
      selected: [],
      search: "",
      items: [],
      excelName: "liste des " + this.referentielName + "s.xls",
      activeEditableItem: {},
    };
  },
  created() {
    this.loadData();
  },
  watch: {
    emptyReferentielItem: {
      handler() {
        this.activeEditableItem = { ...this.emptyReferentielItem };
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    computedItemsList() {
      let computedItems = [...this.items];
      let totalFieldKey = this.tableFields[1].key;
      let row = { [totalFieldKey]: "TOTAL", isSummaryRow: true };
      let totalComputingFieldsKeys = this.totalComputingFields.map((el) => el.key);
      this.tableFields.forEach(({ key }) => {
        const cField = key;
        if (totalComputingFieldsKeys.includes(key)) {
          this.items.forEach((item) => {
            if (row[cField] == undefined) row[cField] = 0;
            row[cField] += item[cField];
          });
        } else {
          if (key != totalFieldKey) row[cField] = "-";
        }
      });
      return this.totalComputingFields.length > 0 && this.items.length > 0
        ? [...computedItems, row]
        : computedItems;
    },
  },
  methods: {
    isDate(date) {
      //   return this.$dayjs(date).isValid();
      return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
    },
    loadData() {
      this.fetchReferentielList();
    },
    fetchReferentielList() {
      get(`/${this.crudUrl}/?${this.extraQueryArg}&size=100`).then(({ data }) => {
        this.items = data.content;
      });
    },
    createOrUpdateReferentiel(item) {
      let method =
        item.id == null
          ? post(`/${this.crudUrl}/`, item)
          : put(`/${this.crudUrl}/${item.id}/`, item);
      return method.then(({ data }) => {
        this.$emit("change", data);
        return data;
      });
    },
    deleteReferentiel(item) {
      remove(`/${this.crudUrl}/${item.id}/`).then(() => {
        this.$emit("change", data);
        this.loadData();
      });
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    editReferentiel(referentiel) {
      this.activeEditableItem = { ...referentiel };
      this.$emit("activeEditableItem", this.activeEditableItem);
      this.$refs["crudTableEditor"].setActiveItem(referentiel);
      this.$refs["crudTableEditor"].show();
    },
    async destroyReferentiel(referentiel) {
      this.deleteReferentiel(referentiel);
      //   await this.$confirm({
      //     size: "md",
      //     centered: true,
      //     button: {
      //       no: "Non",
      //       yes: "Oui",
      //     },
      //     message: "Voulez-vous vraiment supprimer cet élément?",
      //     callback: (confirm) => {
      //       if (confirm) {
      //         this.deleteReferentiel(referentiel);
      //       }
      //     },
      //   });
    },
    updateOrCreateReferentiel(referentiel) {
      console.log(referentiel);
      let data = referentiel;

      if (this.dataFormatter instanceof Function) {
        data = this.dataFormatter(referentiel);
      }

      this.createOrUpdateReferentiel(data)
        .then(() => {
          this.loadData();

          this.$toast.success("Opération réussie!", {
            position: "top-right",
            duration: 5000,
          });
          this.activeEditableItem = { ...this.emptyReferentielItem };
        })
        .catch((err) => {
          this.$error.success(err.message, {
            position: "top-right",
            duration: 5000,
          });
        });
    },
  },
};
</script>

<style></style>
