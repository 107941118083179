<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <div class="form-floating mb-3">
            <input
              :value="editableItem.nom"
              @input="handleInput"
              required
              type="text"
              v-uppercase
              class="form-control"
              id="nom"
              name="nom"
              placeholder="Votre nom"
            />
            <label for="nom">Nom</label>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
              err
            }}</span>
          </div>
        </ValidationProvider>
        <ValidationProvider rules="required" v-slot="{ errors }">
          <div class="form-floating mb-3">
            <input
              :value="editableItem.prenom"
              @input="handleInput"
              type="text"
              v-uppercase
              class="form-control"
              id="prenom"
              name="prenom"
              placeholder="Votre prénom"
            />
            <label for="prenom">Prénom</label>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
              err
            }}</span>
          </div>
        </ValidationProvider>
        <div class="row">
          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <div class="field col-12 md:col-12">
                  <label for="dateNais">Date de naissance</label>
                  <p-calendar
                    id="dateNais"
                    class="col-12 md:col-12"
                    :showIcon="true"
                    size="sm"
                    locale="fr"
                    v-model="editableItem.dateNais"
                    name="dateNais"
                    :manualInput="false"
                    dateFormat="dd/mm/yy"
                  ></p-calendar>
                </div>

                <!-- <vue-timepicker ref="picker" style="display: none;" v class="form-control"></vue-timepicker> -->
                <!-- <input type="text" v-uppercase @click.prevent="$refs.picker.click()" class="form-control"> -->

                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <ValidationProvider rules="" v-slot="{ errors }">
            <h5>
              Dispose de son Certificat d'identification Personnelle ou de sa carte
              d'identité biométrique?
            </h5>
            <div class="form-floating mb-3">
              <!-- Valeur: {{editableItem.hasNip}} -->
              <!-- <c-yes-or-no-input
                v-model="editableItem.hasNip"
                question="Dispose de son Certificat d'identification Personnelle ou de sa carte d'identité biométrique?"
              ></c-yes-or-no-input> -->
              <div class="form-check form-check-inline">
                <input
                  v-model="editableItem.hasNip"
                  class="form-check-input"
                  type="radio"
                  id="hasNip1"
                  name="hasNip"
                  :value="true"
                />
                <label class="form-check-label" for="hasNip1">Oui</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  v-model="editableItem.hasNip"
                  class="form-check-input"
                  type="radio"
                  id="hasNip2"
                  name="hasNip"
                  :value="false"
                />
                <label class="form-check-label" for="hasNip2">Non</label>
              </div>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
          <ValidationProvider
            v-if="editableItem.hasNip === true"
            rules="required|equal:10|numeric"
            v-slot="{ errors }"
          >
            <div class="form-floating mb-3">
              <input
                :value="editableItem.npi"
                @input="handleInput"
                type="text"
                v-uppercase
                class="form-control"
                id="npi"
                name="npi"
                placeholder="Votre NPI"
              />
              <label for="npi">Numéro Personnel d’Identification (10 positions)</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div>
          De quelles autres pièces nationales d’identification valides disposez-vous?
        </div>
        <block-piece-identite
          title=""
          v-model="editableItem.pieces"
          :azoli="editableItem"
        />

        <contacts v-model="editableItem" />
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import CYesOrNoInput from "@/components/CYesOrNoInput.vue";
import BlockPieceIdentite from "../BlockPieceIdentite.vue";
import Contacts from "../../fiche/steps/Contacts.vue";

export default {
  props: ["value"],
  components: {
    CYesOrNoInput,
    BlockPieceIdentite,
    Contacts,
  },
  data() {
    return {
      // hasNip: null,
      pieces: [],
      editableItem: { ...this.value },
      dateNais: this.$dayjs(this.value.dateNais).format("L"),
    };
  },
  computed: {
    maxDate() {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      today.setFullYear(now.getFullYear() - 15);
      return today;
    },
  },
  watch: {
    dateNais(val) {
      this.editableItem.dateNais = this.$dayjs(val).format("L");
      this.$emit("input", this.editableItem);
    },
    editableItem: {
      handler() {
        this.$emit("input", this.editableItem);
      },
      deep: true,
    },
    "editableItem.pieces": {
      handler() {
        this.$emit("input", this.editableItem);
      },
      deep: true,
    },

    "editableItem.hasNip": {
      handler() {
        if (this.editableItem.hasNip == false) {
          this.editableItem.npi = null;
        }
      },
    },
  },
  methods: {
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
  },
};
</script>

<style scoped></style>
