<template>
  <div>
    <div v-if="displayMode!='pretty'" class="d-flex justify-content-between align-items-center mb-4">
      <emploi-editor-dialog
        v-show="false"
        @save="saveEmploi"
        :title="
          activeItem != null && activeItem.emploi == null
            ? 'Ajouter emploi'
            : 'Editer emploi'
        "
        ref="editor"
        :item="activeItem"
      />
      <slot name="header" :addEmploi="addEmploi">
        <h6 class="mb-0 text-uppercase flex-fill">Emplois</h6>
        <!--<button
          @click.prevent="addEmploi()"
          type="button"
          class="btn btn-primary"
        >
          Ajouter
        </button> -->
      </slot>
    </div>
    <div else>
      <h3 class="">EXPÉRIENCE PROFESSIONNELLE</h3>
    </div>
    <div class="mb-5">
      <div class="mb-4">
        <div
          class="d-flex justify-content-between mb-2 align-items-center"
          v-for="(empl, index) in emplois"
          :key="index"
        >
          <template  v-if="displayMode=='pretty'">
            <div class="border-light p-2 py-2 w-100">
              <div class="">
                <h5 class="mb-0 text-uppercase">{{ empl.intitule }} | {{ empl.dateDeb | dayjs('YYYY') }} - {{ empl.dateFin | dayjs('YYYY')  }}</h5>
                <small v-if="empl.typePosition" class="text-muted">{{ empl.typePosition.libelle }}</small>
              </div>
              <div class="tache">
                <h6 class="text-bold mt-1">Tâche</h6>
                <div>
                  - {{ empl.tache }}
                </div>
              </div>
            </div>
          </template>
          <div class="mb-2" v-else>
            <template v-if="displayMode=='nice'">
              <div class="">
                <h5 class="mb-0 text-uppercase">{{ empl.intitule }} | {{ empl.dateDeb | dayjs('YYYY') }} - {{ empl.dateFin | dayjs('YYYY')  }}</h5>
                <small v-if="empl.typePosition" class="text-muted">{{ empl.typePosition.libelle }}</small>
              </div>
              <div class="tache">
                <h6 class="text-bold mt-1">Tâche</h6>
                <div>
                  - {{ empl.tache }}
                </div>
              </div>
            </template>
            <table class="table table-borderless" v-if="displayMode=='table'">
              <thead class="border-bottom border-top">
                <tr>
                  <th scope="col">Structure</th>
                  <th scope="col">Position</th>
                  <th scope="col">Nature du contrat</th>
                  <th scope="col">Compétance pricipale</th>
                  <th scope="col">Date début</th>
                  <th scope="col">Date fint</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span> {{ empl.intitule || "-" }}</span>
                  </td>
                  <td>
                    <span v-if="empl.typePosition"> {{ empl.typePosition.libelle || "-" }}</span>
                  </td>
                  <td>
                    <span> {{ empl.nature.description || "-" }}</span>
                  </td>
                  <td>
                    <span> {{ empl.competence.intitule || "-" }}</span>
                  </td>
                  <td>
                    <span> {{ empl.dateDeb | dayjs("DD/MM/YYYY") }}</span>
                  </td>
                  <td>
                    <span> {{ empl.dateFin | dayjs("DD/MM/YYYY") }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
         <!-- <div v-if="displayMode != 'pretty'">
            <a
              @click.prevent="updateEmploi(empl)"
              class="btn btn-success rounded-0 btn-sm"
              ><i class="bi bi-pencil-square"></i
            ></a>
            <a
              @click.prevent="deleteEmploi(empl)"
              class="btn btn-danger rounded-0 btn-sm"
              ><i class="bi bi-trash"></i
            ></a>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EmploiEditorDialog from "../editor/dialog/EmploiEditorDialog.vue";
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    displayMode: {
      type: String,
      default: 'nice'
    }
  },
  components: { EmploiEditorDialog },
  data() {
    return {
      // editableItem: this.value,
      activeItem: {},
      emplois: [ ...this.value ],
    };
  },
  watch: {
    // emplois(val) {
    //   this.$emit("input", val);
    // },   
    value(val){
      this.emplois = [ ...val ]
    }
  },
  computed: {
    emptyEmploi() {
      return {
       
        intitule: null,
        position: null,
        typePosition: null,
        descriptionTache: null,
        mensualite: null,
        competencePrincipale: null,
        nature: null, //Nature du contrat
        dateDeb: null,
        dateFin: null,
        competenceSecondaires: [],
      };
    },
  },
 
  methods: {
     
    updateEmploi(emp) {
      this.activeItem = { ...emp };
      this.$refs.editor.show();
    },
    addEmploi() {
      // this.emplois.push(emp)
      this.activeItem = { ...this.emptyEmploi };
      this.$refs.editor.show();
    },
    saveEmploi(emp) {
      // this.emplois = this.emplois.filter(
      //   (item) => item.typePosition && emp.typePosition && item.typePosition.id != emp.typePosition.id
      // );

      this.emplois.push(emp);
      this.activeItem = { ...this.emptyEmploi };
      this.$emit("save", this.emplois);
      
    },
    deleteEmploi(emp) {
      this.emplois = this.emplois.filter(
        (item) => item.typePosition && emp.typePosition && item.typePosition.id != emp.typePosition.id
      );
    },
  },
};
</script>
