<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 mt-5 mb-4">
        <p-progress-bar v-if="loading" style="height: 0.33em" mode="indeterminate" />

        <DemandeurCiviliteEditDialog ref="civiliteEditDialog" :demandeur="demandeur" />

        <div
          class="card-booking-completed pa-5 mb-5 d-flex flex-column justify-content-center"
          :height="550"
          v-if="!loading"
        >
          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center justify-content-between">
                I. Identification du DE {{ editMode ? " (modification)" : "" }}
                <span
                  ><b-btn size="xs" @click.prevent="editFiche" variant="success"
                    ><i class="bi bi-pencil"></i></b-btn
                ></span>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Nom</h6>
                </div>
                <div class="col-sm-7 text-secondary">{{ demandeur.nom }}</div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Prénom(s)</h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  {{ demandeur.prenom }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Sexe</h6>
                </div>
                <div class="col-sm-7 text-secondary">{{ demandeur.sexe }}</div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Date de naissance</h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  {{ $dayjs(demandeur.dateNais).format("DD/MM/YYYY") }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Numéro NPI</h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  {{ demandeur.npi || "-" }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Téléphone</h6>
                </div>
                <div class="col-sm-12">
                  <table class="table table-borderless">
                    <thead class="border-bottom border-top">
                      <tr>
                        <th scope="col">Principal</th>
                        <th scope="col">Whatsapp</th>
                        <th scope="col">Alternatif</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span> {{ demandeur.phone || "-" }}</span>
                        </td>
                        <td>
                          <span> {{ demandeur.whatsapp || "-" }}</span>
                        </td>
                        <td>
                          <span> {{ demandeur.phoneAlternatif || "-" }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Courriel (email)</h6>
                </div>
                <div class="col-sm-7 text-secondary">{{ demandeur.email }}</div>
              </div>
              <hr />
            </div>
          </div>
          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-cente justify-content-between">
                II. Domicile
                <span
                  ><b-btn size="xs" @click.prevent="editFiche" variant="success"
                    ><i class="bi bi-pencil"></i></b-btn
                ></span>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Département</h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  {{ demandeur.departement.nom || "-" }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Commune</h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  {{ demandeur.commune.nom || "-" }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Arrondissement</h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  {{ demandeur.arrondissement.nom || "-" }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-5">
                  <h6 class="mb-0">Village/Quartier de ville</h6>
                </div>
                <div class="col-sm-7 text-secondary">
                  {{ demandeur.ville.nom || "-" }}
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-sm-12">
                  <table class="table table-borderless">
                    <thead class="border-bottom border-top">
                      <tr>
                        <th scope="col">Rue</th>
                        <th scope="col">Carrée</th>
                        <th scope="col">Maison</th>
                        <th scope="col">Hameau</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <span> {{ demandeur.rue || "-" }}</span>
                        </td>
                        <td>
                          <span> {{ demandeur.carre || "-" }}</span>
                        </td>
                        <td>
                          <span> {{ demandeur.maison || "-" }}</span>
                        </td>
                        <td>
                          <span> {{ demandeur.hameau || "-" }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div
          class="card-booking-completed pa-5 mb-5 mx-5 d-flex flex-column justify-content-center"
          :height="550"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ProfilePhotoDragAndDrop from "../../../components/uploader/ProfilePhotoDragAndDrop.vue";
import { API_URL } from "../../../config/api-config";
import DemandeurCiviliteEditDialog from "./DemandeurCiviliteEditDialog.vue";

export default {
  props: {
    demandeur: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ProfilePhotoDragAndDrop,
    DemandeurCiviliteEditDialog,
  },
  data() {
    return {
      //   demandeur: {},
      activeEditableStep: null,
      loading: false,
      editMode: false,
      API_URL,
    };
  },
  created() {
    // this.demandeur = localStorage.getItem('subscription') != undefined? JSON.parse(localStorage.getItem('subscription')) : this.demandeur
    // this.currentStep = localStorage.getItem('currentStep') != undefined? localStorage.getItem('currentStep') : this.currentStep
    // if (this.demandeurId) {
    //   this.loading = true;
    //   this.fetchDossier({
    //     demandeurId: this.demandeurId,
    //   }).then(() => (this.loading = false));
    // }
  },
  watch: {},
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
    }),
    de() {
      return this.demandeur;
    },
  },
  methods: {
    ...mapActions({
      fetchDossier: "demandeur/fetchDossier",
    }),
    editFiche() {
      this.$refs.civiliteEditDialog.show();
    },
    saveFiche() {
      alert("save fiche");
    },
    detailUpdated(detail) {
      this.demandeur = {
        ...detail,
        ...detail.azoli,
      };
      this.editMode = false;
    },
  },
};
</script>

<style scoped>
.card-title {
  color: #1a32c8 !important;
  /* color: #1A32C8 !important; */
  text-transform: uppercase;
}
.p-dialog .p-dialog-content {
  padding: 0px;
}
hr {
  color: #bfa9a9;
  margin: 10px 5px;
}
</style>
