import { render, staticRenderFns } from "./DemandeurDetailInstruit.vue?vue&type=template&id=23b76424&scoped=true"
import script from "./DemandeurDetailInstruit.vue?vue&type=script&lang=js"
export * from "./DemandeurDetailInstruit.vue?vue&type=script&lang=js"
import style0 from "./DemandeurDetailInstruit.vue?vue&type=style&index=0&id=23b76424&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23b76424",
  null
  
)

export default component.exports