<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="card">
          <ProgrammeBeneficieEditor
            :title="
              activeProgramme && activeProgramme.id
                ? 'Ajouter programme'
                : 'Modifier programme'
            "
            :item="activeProgramme"
            ref="programmeBeneficieEditor"
            @save="saveProgrammeItem"
          />
          <slot name="header" :addProgramme="addProgramme">
            <div class="d-flex justify-content-between">
              <div class="mb-0 text-uppercase d-flex justify-content-between">
                <span>Programme</span>
                <button
                  @click.prevent="addProgramme()"
                  type="button"
                  class="btn btn-primary"
                >
                  Ajouter
                </button>
              </div>
            </div>
          </slot>
          <PDataTable
            class="shadow-none"
            :value="programmes"
            :expandedRows.sync="expandedRows"
            stripedRows
            editMode="row"
            dataKey="id"
            :editingRows.sync="editingRows"
          >
            <PColumn field="programme" :styles="{ width: colWidth }" header="Programme">
              <template #body="slotProps">
                <a href="" @click.prevent="toggleExpandRow(slotProps.data)">
                  {{ slotProps.data.programme.nom }}
                  <!-- <PBadge v-if="slotProps.data.contenus.length>0" :value="slotProps.data.contenus.length" severity="primary"></PBadge> -->
                </a>
              </template>
            </PColumn>
            <PColumn field="dateDebut" :styles="{ width: colWidth }" header="Date Début">
              <template #editor="slotProps">
                <PInputText v-model="slotProps.data[slotProps.column.field]" />
              </template>
              <template #body="slotProps">
                <span v-if="slotProps.data[slotProps.column.field]">{{
                  slotProps.data[slotProps.column.field] | dayjs("DD-MM-YYYY HH:mm")
                }}</span>
                <span v-else>NA</span>
              </template>
            </PColumn>
            <PColumn field="dateFin" :styles="{ width: colWidth }" header="Date Fin">
              <template #editor="slotProps">
                <PInputText v-model="slotProps.data[slotProps.column.field]" />
              </template>
              <template #body="slotProps">
                <span v-if="slotProps.data[slotProps.column.field]">{{
                  slotProps.data[slotProps.column.field] | dayjs("DD-MM-YYYY HH:mm")
                }}</span>
                <span v-else>NA</span>
              </template>
            </PColumn>
            <PColumn :styles="{ width: colWidth }" header="Action">
              <template #body="slotProps">
                <div class="d-flex justify-space-between">
                  <PButton
                    @click.prevent="$refs.programmeBeneficieEditor.show()"
                    icon="pi pi-pencil"
                    class="p-button-rounded p-button-primary"
                    @click="editProgrammeItem(slotProps.data)"
                  />
                  <PButton
                    icon="pi pi-trash"
                    class="p-button-rounded p-button-warning"
                    @click="confirmDeleteItem(slotProps.data)"
                  />
                </div>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgrammeBeneficieEditor from "./editor/ProgrammeBeneficieEditor.vue";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { mapActions } from "vuex";

export default {
  props: {
    // value: {
    //   type:  Array,
    //   required: true
    // },
    extraQueryArg: {
      type: String,
      default: "",
    },
    demandeur: {
      type: Object,
      required: true,
    },
    prescription: {
      type: Object,
      required: true,
    },
  },
  mixins: [paginatorMixin],
  components: {
    ProgrammeBeneficieEditor,
  },
  data() {
    return {
      display: false,
      programmes: [],
      expandedRows: [],
      search: "",
      editingRows: [],
      activeProgramme: null,
    };
  },
  created() {
    this.loadData();
    // this.fetchParticipationProgrammes().then(data => this.programmes = [...data])
  },
  watch: {
    mxFilteredItems() {
      this.programmes = [...this.mxFilteredItems];
    },
  },
  computed: {
    colWidth() {
      return "100%";
    },
    etats() {
      return [
        {
          value: "NON_REALISE",
          label: "Non Réalisé",
        },
        {
          value: "EN_COURS",
          label: "En cours",
        },
        {
          value: "TERMINE",
          label: "Terminé",
        },
      ];
    },
    emptyProgramme() {
      return {
        participantId: this.demandeur.id,
        participant: this.demandeur,
        prescription: this.prescription,
        observation: null,
        programme: null,
        dateDebut: null,
        dateFin: null,
        statut: null,
      };
    },
  },
  methods: {
    ...mapActions({
      fetchParticipationProgrammes: "participationProgramme/fetchParticipationProgrammes",
      createParticipationProgramme: "participationProgramme/createParticipationProgramme",
      updateParticipationProgramme: "participationProgramme/updateParticipationProgramme",
      deleteParticipationProgramme: "participationProgramme/deleteParticipationProgramme",
      createOrUpdateParticipationProgramme:
        "participationProgramme/createOrUpdateParticipationProgramme",
    }),
    loadData() {
      this.mxInitializePaginator({
        queryModel: "participationProgramme",
        fetcherMethod: "participationProgramme/fetchParticipationProgrammes",
        dataGetter: "participationProgramme/participationProgrammes",
        paginationGetter: "participationProgramme/pagination",
        autoSubmitQuery: false,
        search: this.search,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 10,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: [],
        searchFields: [],
        extraQueryArgsParamBuilder: () => {
          return `&${this.extraQueryArg}`;
        },
      });
    },
    confirmDeleteItem(item) {
      this.deleteParticipationProgramme(item).then(() => {
        this.programmes = this.programmes.filter((prog) => prog.id != item.id);
        this.$toast.success("Contenu supprimé avec succès!");
      });
    },
    saveProgrammeItem(item) {
      this.$emit("save", this.programmes);
      this.$emit("input", this.programmes);

      this.createOrUpdateParticipationProgramme({
        ...item,
        participant: this.demandeur,
        participantId: this.demandeur.id,
      })
        .then((data) => {
          this.programmes = this.programmes.filter(
            (prog) => prog.programme.id != data.programme.id
          );
          this.programmes.unshift(data);

          this.$toast.success("Opération réussie");
        })
        .catch((err) => this.$toast.error("Une erreur s'est produite." + err));
    },
    addProgramme() {
      this.activeProgramme = { ...this.emptyProgramme };
      this.$refs.programmeBeneficieEditor.show();
    },
    editProgrammeItem(prog) {
      this.activeProgramme = { ...prog };
      this.$refs.programmeBeneficieEditor.show();
    },
  },
};
</script>
