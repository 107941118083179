<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <qualification-editor-dialog
        v-show="false"
        @save="saveQualification"
        :title="
          activeItem != null && activeItem.qualification == null
            ? 'Ajouter qualification'
            : 'Editer qualification'
        "
        ref="editor"
        :item="activeItem"
      />
      <slot name="header" :addQualification="addQualification">
        <h6 class="mb-0 text-uppercase flex-fill">Qualifications</h6>
        <button
          @click.prevent="addQualification()"
          type="button"
          v-if="!readOnly"
          class="btn btn-primary"
        >
          Ajouter
        </button>
      </slot>
    </div>
    <slot
      :addQualification="addQualification"
      :isEmpty="qualifications.length == 0"
    ></slot>
    <div class="mb-5">
      <div
        class="d-flex justify-content-between mb-2 align-items-center"
        v-for="(qualif, index) in qualifications"
        :key="index"
      >
        <div class="">
          <small class=""
            >{{ qualif.dateDeb | dayjs("YYYY") }} à
            {{ qualif.dateFin | dayjs("YYYY") }} |
            <span v-if="qualif.pays != null"
              >{{ qualif.ville }} - {{ qualif.pays.nom }}</span
            ></small
          >
          <h5 class="mb-0">{{ qualif.diplome }}</h5>
          <small class="text-muted">{{ qualif.centreDeFormation }}</small>
        </div>
        <div v-if="!readOnly">
          <a
            @click.prevent="updateQualification(qualif, index)"
            class="btn btn-success rounded-0 btn-sm"
            ><i class="bi bi-pencil-square"></i
          ></a>
          <a
            @click.prevent="removeQualification(qualif, index)"
            class="btn btn-danger rounded-0 btn-sm"
            ><i class="bi bi-trash"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QualificationEditorDialog from "./editor/dialog/QualificationEditorDialog.vue";
import { mapActions } from "vuex";

export default {
  components: { QualificationEditorDialog },
  props: {
    value: {
      type: Array,
    },
    demandeur: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeItem: {},
      activeItemIndex: -1,
      qualifications: [...this.value],
    };
  },
  //   watch: {
  //     value: {
  //       handler() {
  //         this.qualifications = [...this.value];
  //       },
  //       deep: true,
  //     },
  //   },
  computed: {
    emptyQualification() {
      return {};
    },
  },
  methods: {
    ...mapActions({
      createOrUpdateQualification: "qualification/createOrUpdateQualification",
      deleteQualification: "qualification/deleteQualification",
    }),
    updateQualification(qualif, index) {
      this.activeItem = { ...qualif };
      this.activeItemIndex = index;
      this.$refs.editor.show();
    },
    addQualification() {
      this.activeItem = { ...this.emptyQualification };
      this.activeItemIndex = -1;
      this.$refs.editor.show();
    },
    saveQualification(qualif) {
      //   if (this.activeItemIndex == -1) {
      //     this.qualifications.push(qualif);
      //   } else {
      //     this.qualifications[this.activeItemIndex] = qualif;
      //   }
      //   this.activeItem = { ...this.emptyQualification };
      //   this.activeItemIndex = -1;
      //   this.$emit("input", this.qualifications);
      //   this.$emit("change", this.qualifications);
      this.createOrUpdateQualification({
        ...qualif,
        offreurId: this.demandeur.id,
      }).then((savedItem) => {
        qualif = savedItem;
        this.qualifications = this.qualifications.filter((item) => item.id != qualif.id);
        this.qualifications.push(qualif);
        this.activeItem = { ...this.emptyQualification };
      });
    },
    removeQualification(qualif, index) {
      this.deleteQualification(qualif).then((el) => {
        this.qualifications = this.qualifications.filter((item) => item.id != qualif.id);
      });

      //   this.qualifications = this.qualifications.filter((qualif, idx) => idx != index);
      //   this.$emit("input", this.qualifications);
      //   this.$emit("change", this.qualifications);
      //   this.activeItem = { ...this.emptyQualification };
      //   this.activeItemIndex = -1;
    },
  },
};
</script>
